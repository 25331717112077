<template>
  <FormModal title="Waar wil je de dakkapel plaatsen?" show-back-btn>
    <template #body>
      <DormerPlacementFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import DormerPlacementFormStep from '~/components/form/steps/dormerPlacement/DormerPlacementFormStep'

export default {
  components: {
    DormerPlacementFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Waar wil je de dakkapel plaatsen?',
      headDescription: 'Geef aan waar de dakkapel geplaatst moet worden.',
      path: '/offertes-aanvragen/locatie-dakkapel',
      progressValue: 60,
      checkoutStep: 4,
    }
  },
}
</script>
