<template>
  <FormModal title="Wanneer wil je de dakkapel plaatsen?" show-back-btn>
    <template #body>
      <ExecutionPeriodFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import ExecutionPeriodFormStep from '~/components/form/steps/executionPeriod/ExecutionPeriodFormStep'

export default {
  components: {
    ExecutionPeriodFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Wanneer wil je de dakkapel plaatsen?',
      headDescription: 'Geef aan wanneer je de dakkapel wilt plaatsen.',
      path: '/offertes-aanvragen/wanneer-dakkapel-plaatsen',
      progressValue: 75,
      checkoutStep: 5,
    }
  },
}
</script>
