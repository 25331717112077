<template>
  <ExecutionPeriodRadioField :choices="choices" v-on="$listeners" />
</template>

<script>
import ExecutionPeriodRadioField from 'chimera/all/components/form/fields/executionPeriod/ExecutionPeriodRadioField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'ExecutionPeriodRadioFormPart',

  components: {
    ExecutionPeriodRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable(
          'Zo snel mogelijk',
          'Zo snel mogelijk',
          'Zo snel mogelijk',
        ),
        new Selectable('1-3 maanden', '1-3 maanden', '1-3 maanden'),
        new Selectable('4-6 maanden', '4-6 maanden', '4-6 maanden'),
        new Selectable('In overleg', 'In overleg', 'In overleg'),
      ],
    }
  },
}
</script>
