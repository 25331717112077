<template>
  <DormerPlacementRadioField :choices="choices" v-on="$listeners" />
</template>

<script>
import DormerPlacementRadioField from 'chimera/all/components/form/fields/dormerPlacement/DormerPlacementRadioField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'DormerPlacementRadioFormPart',

  components: {
    DormerPlacementRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable(
          'Aan de achterkant van mijn woning',
          'Aan de achterkant van mijn woning',
          'Aan de achterkant van mijn woning',
        ),
        new Selectable(
          'Aan de voorkant van mijn woning',
          'Aan de voorkant van mijn woning',
          'Aan de voorkant van mijn woning',
        ),
        new Selectable(
          'Aan de zijkant van mijn woning',
          'Aan de zijkant van mijn woning',
          'Aan de zijkant van mijn woning',
        ),
        new Selectable(
          'Weet ik nog niet',
          'Weet ik nog niet',
          'Weet ik nog niet',
        ),
      ],
    }
  },
}
</script>
